import { auth } from './init'
import { GoogleAuthProvider, GithubAuthProvider, signInWithRedirect, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, sendPasswordResetEmail, signOut } from 'firebase/auth'
import { updateName } from './firestore'

const googleProvider = new GoogleAuthProvider()
const githubProvider = new GithubAuthProvider()

const googleSignIn = async () => {
    try {
        await signInWithRedirect(auth, googleProvider)
    } catch (err) {
        console.error(err)
        alert(err.message)
    }
}

const githubSignIn = async () => {
    try {
        await signInWithRedirect(auth, githubProvider)
    } catch (err) {
        console.error(err)
        alert(err.message)
    }
}

const emailSignIn = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password)
        return null
    } catch (err) {
        console.error(err)
        return err
    }
}

const registerUser = async (email, password, name) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password)
        await updateProfile(auth.currentUser, { displayName: name, photoURL: `https://ui-avatars.com/api/?background=random&size=128&name=${name}` })
        await updateName(auth.currentUser.uid, name)
        return null
    } catch (err) {
        console.error(err)
        return err
    }
}

const resetPassword = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email)
        alert("Password reset link sent!")
    } catch (err) {
        console.error(err)
        alert(err.message)
    }
}

const logout = async () => await signOut(auth)

export {
    googleSignIn,
    githubSignIn,
    emailSignIn,
    registerUser,
    resetPassword,
    logout
}