import { useContext, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'

import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap'

import Header from 'components/Headers/Header'
import PlanCard from 'components/Custom/PlanCard'

import { UserContext } from 'lib/context'
import { plans, availablePlans } from 'constants/shared'

const Subscription = () => {
  const { subscription, accounts, profile } = useContext(UserContext)

  const [activePlan, setActivePlan] = useState(null)

  const [loading, setLoading] = useState(false)

  const stripe = useStripe()

  const purchasePlan = async (id, priceId) => {
    setLoading(true)

    try {
      const res = await fetch('http://localhost:5001/cloudinspekt/us-central1/stripe/createSession', {
        method: 'POST',
        body: JSON.stringify({ price: priceId, customer: profile.customerId, uid: profile.uid, plan: id }),
        headers: { 'Content-Type': 'application/json' }
      })

      const { id: sessionId } = await res.json()
      const { error } = await stripe.redirectToCheckout({ sessionId })
      if (error) {
        console.error(error)
        alert(error.message)
      }
    } catch (err) {
      console.error(err)
    }

    setLoading(false)
  }

  useEffect(() => {
    if(subscription){
      const plan = plans.filter(plan => plan.priceId === subscription.priceId)[0]
      setActivePlan(plan)
    }
  }, [subscription])

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <h2 className="mb-0 text-center">Purchased Plan</h2>
              </CardHeader>
              <CardBody>
                { subscription && accounts && activePlan
                  ? <Row>
                      <PlanCard plan={subscription} subscribed={true} />
                      <Col xl="7">
                        <Card className="mt-3">
                          <CardHeader>
                            <h3>Details</h3>
                          </CardHeader>
                          <CardBody>
                            <Col>
                              <p>Plan Status: { subscription.expirationDate < Date.now() ? <Badge color="danger">Inactive</Badge> : <Badge color="success">Active</Badge> }</p>
                              <p>Purchase Date: <Badge color="primary">{new Date(subscription.purchaseDate).toUTCString()}</Badge></p>
                              <p>Active till: <Badge color={subscription.expirationDate > Date.now() ? 'primary' : 'danger'}>{new Date(subscription.expirationDate).toUTCString()}</Badge></p>
                              <p>Accounts added: <Badge color={accounts.length < activePlan.accounts ? 'primary' : 'warning'}>{accounts.length}/{activePlan.accounts}</Badge></p>
                              <p>Scans used: <Badge color={subscription.scans > 0 ? 'primary' : 'warning'}>{activePlan.scans - subscription.scans}/{activePlan.scans}</Badge></p>
                            </Col>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  : <Row className="justify-content-center"><i className="fas fa-circle-notch fa-spin fa-2x py-6" /></Row> }
              </CardBody>
            </Card>
          </div>
        </Row>
        
        {/* Pricing table */}
        {/* { subscription && (subscription.id === '0' || subscription.expirationDate < Date.now() || subscription.scans <= 0) && <Row className="mt-5">
          <div className="col">
            <Card className="shadow bg-gradient-default">
              <CardHeader className="bg-transparent">
                <h2 className="mb-0 text-center text-white">Get a new plan</h2>
              </CardHeader>
              <hr className="bg-light my-1" />
              <CardBody className="mb-4">
                <Row className="justify-content-center">
                  { loading ? <i className="fas fa-circle-notch fa-spin fa-2x py-7" /> : availablePlans.map(plan => <PlanCard plan={plan} getPlan={purchasePlan} key={plan.id} />) }
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row> } */}

      </Container>
    </>
  );
};

export default Subscription;
