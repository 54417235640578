export const mailFormat = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/

export const passwordFormat = [{
        name: 'length',
        regex: /^.{8,12}$/,
        message: 'Length must be between 8-12 characters'
    },
    {
        name: 'lowercase',
        regex: /^(?=.*[a-z])/,
        message: 'At least one lowercase letter'
    },
    {
        name: 'uppercase',
        regex: /^(?=.*[A-Z])/,
        message: 'At least one uppercase letter'
    },
    {
        name: 'number',
        regex: /^(?=.*[0-9])/,
        message: 'At least one number'
    },
    {
        name: 'special',
        regex: /^(?=.*[#?!@$%^&*-])/,
        message: 'At least one special character (#?!@$%^&*-)'
    },
]

export const plans = [
    { 
        name: 'Trial',
        price: 0,
        accounts: 1,
        scans: 10,
        compliances: ['CIS', 'HIPAA', 'PCI'],
        id: '0',
        priceId: '0',
        active: false,
        recommended: false,
        trial: true,
    },
    {
        name: 'Starter',
        price: 99,
        accounts: 1,
        compliances: ['CIS'],
        scans: 10,
        id: 'prod_KUtvjwa1iqihKS',
        priceId: 'price_1Jpu83SEc8FMaiWVOxsaju2f',
        active: true,
        recommended: false,
        trial: false,
    },
    {
        name: 'Basic',
        price: 199,
        accounts: 3,
        compliances: ['CIS', 'HIPAA'],
        scans: 30,
        id: 'prod_KUtwCg76gRdINM',
        priceId: 'price_1Jpu8WSEc8FMaiWVnLFCzePD',
        active: true,
        recommended: true,
        trial: false,
    },
    {
        name: 'Premium',
        price: 299,
        accounts: 5,
        compliances: ['CIS', 'HIPAA', 'PCI'],
        scans: 50,
        id: 'prod_KUtwQ3TgfVtbKq',
        priceId: 'price_1Jpu8sSEc8FMaiWV9gdIbFau',
        active: true,
        recommended: false,
        trial: false,
    },
]

export const availablePlans = plans.filter(plan => plan.active)

export const columns = [{
        name: "title",
        label: "Title",
        options: {
            filter: false,
            sort: true,
        },
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "category",
        label: "Category",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "region",
        label: "Region",
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: "resource",
        label: "Resource",
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: "message",
        label: "Message",
        options: {
            filter: false,
            sort: false,
        },
    },
    {
        name: "description",
        label: "Description",
        options: {
            filter: false,
            sort: false,
        },
    },
]

export const scanStats = {
    options: {
        scales: {
            yAxes: [{
                ticks: {
                    callback: function (value) {
                        if (!(value % 1)) {
                            return value
                        }
                    },
                },
            }, ],
        },
        tooltips: {
            callbacks: {
                label: function (item, data) {
                    var label = data.datasets[item.datasetIndex].label || "";
                    var yLabel = item.yLabel;
                    var content = "";
                    if (data.datasets.length > 1) {
                        content += label;
                    }
                    content += yLabel;
                    return content;
                },
            },
        },
    },
    data: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [{
            label: "Scans",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            maxBarThickness: 10,
        }, ],
    }
}