import { db } from './init'
import { doc, collection, getDoc, getDocs, setDoc, updateDoc, deleteDoc, query, orderBy } from 'firebase/firestore'
import { v4 as uuid } from 'uuid'

const getUser = async uid => {
    try {
        const userSnap = await getDoc(doc(db, 'users', uid))
        return userSnap.data()
    } catch (e) {
        console.error(e)
    }
}

const getSubscription = async uid => {
    try {
        const subscriptionSnap = await getDoc(doc(db, 'subscriptions', uid))
        return subscriptionSnap.exists() ? subscriptionSnap.data() : false
    } catch (e) {
        console.error(e)
    }
}

const getAccounts = async uid => {
    try {
        const q = query(collection(db, 'users', uid, 'accounts'), orderBy('alias'))
        const accountSnap = await getDocs(q)
        return accountSnap.size > 0 ? accountSnap.docs.map(doc => { 
            const {id, alias, platform, added} = doc.data()
            return {id, alias, platform, added}
        }) : []
    } catch (e) {
        console.error(e)
    }
}

const addAccount = async (uid, alias, platform, keys) => {
    try {
        const id = uuid()
        const docRef = doc(db, 'users', uid, 'accounts', id)
        const account = { id, alias, platform, keys, added: Date.now() }
        await setDoc(docRef, account)
        return null
    } catch (e) {
        console.error(e)
        return e
    }
}

const deleteAccount = async (uid, cid) => {
    try {
        const docRef = doc(db, 'users', uid, 'accounts', cid)
        await deleteDoc(docRef)
    } catch (e) {
        console.error(e)
    }
}

const getScans = async uid => {
    try {
        const q = query(collection(db, 'users', uid, 'scans'), orderBy('created', 'desc'))
        const scanSnap = await getDocs(q)
        return scanSnap.size > 0 ? scanSnap.docs.map(doc => doc.data()) : []
    } catch (e) {
        console.error(e)
    }
}

const addScan = async (uid, cid, alias, platform, compliance, china) => {
    try {
        const comp = compliance === false ? [] : compliance === 'CIS' ? ['cis1', 'cis2'] : [compliance.toLowerCase()]
        const id = uuid()
        const scanRef = doc(db, 'users', uid, 'scans', id)
        const scan = { id, created: Date.now(), status: 'Running', message: 'Running Scan', cid, platform, alias, compliance: comp, china }
        await setDoc(scanRef, scan)
        return null
    } catch (e) {
        console.error(e)
        return e
    }
}

const updateName = async (uid, name) => {
    try {
        const userRef = doc(db, 'users', uid)
        await updateDoc(userRef, { name })
    } catch (e) {
        console.error(e)
    }
}

const updateAddress = async (uid, address) => {
    try {
        const userRef = doc(db, 'users', uid)
        await updateDoc(userRef, { address })
        return null
    } catch (e) {
        console.error(e)
        return e
    }
}

export {
    getUser,
    getSubscription,
    getAccounts,
    addAccount,
    deleteAccount,
    getScans,
    addScan,
    updateName,
    updateAddress,
}