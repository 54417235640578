import { useContext, useState } from 'react'

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Media,
  Modal,
  Table,
  Container,
  Row,
  UncontrolledTooltip
} from 'reactstrap'

import awsLogo from 'assets/img/platforms/aws.png'
import azureLogo from 'assets/img/platforms/azure.png'
import gcpLogo from 'assets/img/platforms/gcp.png'

import Header from 'components/Headers/Header'
import MUIDataTable from 'mui-datatables'

import { UserContext } from 'lib/context'
import { getScanResult } from 'lib/firebase/storage'
import { columns } from 'constants/shared'

const options = {
  filterType: 'checkbox',
  rowsPerPage: 5,
  rowsPerPageOptions: [],
  elevation: 1,
  downloadOptions: {
    filename: `ScanResult-${Date.now()}.csv`
  }
}

const Scans = () => {

  const { profile, scans } = useContext(UserContext)

  const [showModal, setShowModal] = useState(false)
  const [result, setResult] = useState(null)
  const [scan, setScan] = useState(null)

  const getResult = async (scan) => {
    setShowModal(true)
    setScan(scan)
    const res = await getScanResult(profile.uid, scan.id)
    setResult(res)
}

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-center">Scan History</h3>
              </CardHeader>
              { scans && scans.length > 0
                ? <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Platform</th>
                        <th scope="col">Alias</th>
                        <th scope="col">Started at</th>
                        <th scope="col">Type</th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      { scans.map(scan => (
                        <tr key={scan.id}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <img width="60" className="py-2 mr-5" alt={scan.alias} src={scan.platform === 'AWS' ? awsLogo : scan.platform === 'Azure' ? azureLogo : scan.platform === 'GCP' && gcpLogo} />
                              <Media>
                                <span className="mb-0 text-sm">{scan.platform === 'AWS' ? 'Amazon Web Services' : scan.platform === 'Azure' ? 'Microsoft Azure' : scan.platform === 'GCP' && 'Google Cloud Platform' }</span>
                              </Media>
                            </Media>
                          </th>
                          <td>{scan.alias}</td>
                          <td>{new Date(scan.created).toUTCString()}</td>
                          <td>
                            <Badge color="primary">
                              <strong>{ scan.compliance.length === 0 ? 'Deep Scan' : scan.compliance.includes('cis1') ? 'CIS' : scan.compliance[0].toUpperCase() }</strong>
                            </Badge>
                          </td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              { scan.status === 'Running' ? <i className="bg-info" /> : scan.status === 'Completed' ? <i className="bg-success" /> : scan.status === 'Failed' && <i className="bg-danger" /> }{scan.status}
                            </Badge>
                          </td>
                          <td className="text-right">
                            <Button className="btn-icon-only text-default" role="button" size="sm" onClick={e => e.preventDefault()} disabled={scan.status === 'Failed' || scan.status === 'Running'}>
                              { 
                                scan.status === 'Completed' ? <><i className="fas fa-eye text" id={`tooltip-${scan.id}`} onClick={() => getResult(scan)} /><UncontrolledTooltip delay={0} target={`tooltip-${scan.id}`}>Show Result</UncontrolledTooltip></>
                                : scan.status === 'Running' ? <><a className="text-info" href="#!" id={`tooltip-${scan.id}`} onClick={(e) => e.preventDefault()}><i className="fas fa-circle-notch fa-spin" /></a><UncontrolledTooltip delay={0} target={`tooltip-${scan.id}`}>{scan.message}</UncontrolledTooltip></>
                                : scan.status === 'Failed' && <><a className="text-danger" href="#!" id={`tooltip-${scan.id}`} onClick={(e) => e.preventDefault()}><i className="fas fa-info-circle" /></a><UncontrolledTooltip delay={0} target={`tooltip-${scan.id}`}>{scan.message}</UncontrolledTooltip></> 
                              }
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              : <CardBody className="bg-gradient-light"><h4 className="text-center text-white my-7">No scan history found</h4></CardBody> }
            </Card>
          </div>
        </Row>

        <Modal style={{ maxHeight: '90vh', maxWidth: '90vw' }} className="modal-dialog-centered" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <div className="modal-header">
            <h5 className="modal-title mb-0">
              { scan && <>{scan.alias} | {scan.platform} | {new Date(scan.created).toUTCString()}</> }
            </h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setShowModal(false)}>
              <i className="fas fa-window-close" style={{ color: 'red' }}/>
            </button>
          </div>
          <div className="modal-body" >
            { result ? <MUIDataTable title={'Scan Result'} data={result} columns={columns} options={options} /> : <Row className="justify-content-center"><i className="fas fa-circle-notch fa-spin py-6 fa-2x"/></Row> }                               
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default Scans;
