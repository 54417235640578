import { useState, useRef } from 'react'
import { Link } from "react-router-dom"

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap'

import { mailFormat, passwordFormat } from 'constants/shared'
import { registerUser, googleSignIn, githubSignIn } from 'lib/firebase/auth'

const Register = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [privacy, setPrivacy] = useState(false);
  
  const [error, setError] = useState(null);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const emailErrorContainer = useRef(null);
  const emailError = useRef(null);
  const passwordErrorContainer = useRef(null);

  const handleEmail = (e) => {
    emailErrorContainer.current.style.display = 'block';
    setEmail(e.target.value);
    const valid = mailFormat.test(e.target.value);
    if(valid) {
      emailError.current.innerHTML = 'valid';
      emailError.current.classList.replace('text-danger', 'text-success');
      setEmailValid(true);
    } else {
      emailError.current.innerHTML = 'invalid';
      emailError.current.classList.replace('text-success', 'text-danger');
      setEmailValid(false);
    }
  }

  const handlePassword = (e) => {
    passwordErrorContainer.current.style.display = 'block'
    setPassword(e.target.value)
    
    const messages = document.createElement('div')
    let checks = []

    passwordFormat.forEach(format => {
      const valid = format.regex.test(e.target.value)
      valid
      ? messages.innerHTML += `<div><small>${format.message}:&nbsp;&nbsp;<span class="text-success font-weight-700"><i class="fas fa-thumbs-up"/></span></small></div>`
      : messages.innerHTML += `<div><small>${format.message}:&nbsp;&nbsp;<span class="text-danger font-weight-700"><i class="fas fa-thumbs-down"/></span></small></div>`
      checks.push(valid)
    })

    if(checks.includes(false)) setPasswordValid(false)
    else setPasswordValid(true)
    passwordErrorContainer.current.innerHTML = messages.innerHTML
  }

  const handleRegister = async (e) => {
    if(name === '') {
      setError('Name is required')
      setTimeout(() => setError(null), 5000)
      return
    }

    const error = await registerUser(email, password, name)
    if(error) {
      switch(error.code) {
        case 'auth/email-already-in-use':
        case 'auth/credential-already-in-use':
        case 'account-exists-with-different-credential':
          setError('An account with this email already exists')
          break
        case 'auth/missing-email':
        case 'auth/invalid-email':
          setError('Email is invalid')
          break
        case 'auth/timeout':
        case 'auth/too-many-requests':
          setError('Too many requests, please try again after some time')
          break
        case 'auth/weak-password':
          setError('Password is too weak')
          break
        default:
          setError('Unknown error, sorry for the moment')
          break
      }
    } else setError(null)
    setTimeout(() => setError(null), 5000)
  }

  return (
    <>
      <Col lg="6" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-4">
              <small>Sign up with</small>
            </div>
            <div className="text-center">
              <Button className="btn-neutral btn-icon" color="default" href="#pablo" onClick={(e) => googleSignIn()}>
                <span className="btn-inner--icon">
                  <img alt="Google Login" src={require("../../assets/img/icons/common/google.svg").default} />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
              <Button className="btn-neutral btn-icon" color="default" href="#pablo" onClick={(e) => githubSignIn()}>
                <span className="btn-inner--icon">
                  <img alt="Github Login" src={require("../../assets/img/icons/common/github.svg").default} />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign up with credentials</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Name" type="text" onChange={e => setName(e.target.value)} value={name} />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" autoComplete="new-email" onChange={handleEmail} value={email}/>
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic my-4" ref={emailErrorContainer} style={{ display: "none" }}>
                <small>
                  Email:{" "}
                  <span className="text-danger font-weight-700" ref={emailError}>invalid</span>
                </small>
              </div>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" autoComplete="new-password" onChange={handlePassword} value={password}/>
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic my-4" ref={passwordErrorContainer}>
              </div>

              <Row>
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input className="custom-control-input" id="customCheckRegister" type="checkbox" value={privacy} onChange={(e) => setPrivacy(e.target.checked)} />
                    <label className="custom-control-label" htmlFor="customCheckRegister">
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="https://cloudinspekt.com/privacy-policy" target="_blank" rel="noopener noreferrer" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>

              { error && <Alert className="mt-4 text-center" color="danger">
                <span className="alert-inner--icon">
                  <i className="fas fa-exclamation-triangle" />
                </span>{" "}
                <span className="alert-inner--text">&nbsp;&nbsp;{error}</span>
                </Alert> }

              <div className="text-center">
                <Button className="mt-4" color="primary" type="button" onClick={handleRegister} disabled={!(emailValid && passwordValid && privacy && name.length > 0)}>
                  Create account
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col className="text-center" xs="12">
            <Link className="text-light" to="/auth/login">
              <small>Already have an account?</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Register;
