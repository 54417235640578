import { ref, getDownloadURL } from 'firebase/storage'
import { storage } from './init'

const getScanResult = async (uid, sid) => {
    try {
        const resultRef = ref(storage, `gs://${process.env.REACT_APP_PROJECT}.appspot.com/scans/${uid}/${sid}.json`)
        const url = await getDownloadURL(resultRef)
        const res = await fetch(url)
        const data = await res.json()
        return data
    } catch (e) {
        console.error(e)
    }
}

export {
    getScanResult
}