import { useContext, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Container,
  Row,
  Col,
} from 'reactstrap'

import {
  chartOptions,
  parseOptions,
} from 'variables/charts'

import Header from 'components/Headers/Header'

import { UserContext } from 'lib/context'
import { addScan } from 'lib/firebase/firestore'
import { scanStats } from 'constants/shared'
import { useEffect } from 'react'


const ScanWizard = ({ profile, accounts, subscription, scans }) => {

  const history = useHistory()

  const [account, setAccount] = useState(null)
  const [compliance, setCompliance] = useState(null)
  const [awsChina, setAwsChina] = useState(false)

  const [process, setProcess] = useState(false)
  const [message, setMessage] = useState(null)

  const startScan = async () => {
    setProcess(true)
    const { id, alias, platform } = account
    const error = await addScan(profile.uid, id, alias, platform, compliance, awsChina)

    if (error) {
      setMessage({ type: 'error', message: 'Could not start scan' })
      setTimeout(() => setMessage(null), 3000)
      setProcess(false)
    } else {
      setMessage({ type: 'success', message: 'Scan started' })
      history.push('/admin/scans/')
    }

  }

  if(!accounts || !subscription || !subscription || !scans) return <Row className="my-6 justify-content-center"><i className="fas fa-circle-notch fa-spin fa-2x my-4" /></Row>

  if(subscription.expirationDate < Date.now()) return <Row className="my-6 justify-content-center"><Link to="/admin/subscription"><Button className="my-4" color="danger" type="button">Get a new plan</Button></Link></Row>

  if(accounts.length === 0) return <Row className="my-6 justify-content-center"><Link to="/admin/accounts"><Button className="my-4" color="primary" type="button">Add a new account</Button></Link></Row>

  if(scans.length > 0 && scans[0].status === 'Running') return <Row className="my-6 justify-content-center"><Link to="/admin/scans"><Button className="my-4" color="info" type="button">Another scan is already running</Button></Link></Row>

  if(subscription.scans <= 0) return <Row className="my-6 justify-content-center"><Link to="/admin/subscription"><Button className="my-4" color="warning" type="button">All scans consumed</Button></Link></Row>

  return (
    <Row className="justify-content-center">
      <Col sm="12" md="8">
        <Form>
          <FormGroup>
            <Label for="accountSelect"><strong>Select an account to scan</strong></Label>
            <Input type="select" name="select" id="accountSelect" onChange={(e) => setAccount(accounts.filter(acc => acc.id === e.target.value)[0])}>
              <option selected disabled value="">---- Click here to select ----</option>
              { accounts.map(account => <option key={account.id} value={account.id}>{account.platform}: {account.alias}</option>) }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="scanSelect"><strong>Select a scan type</strong></Label>
            { subscription && subscription.compliances.map(comp => <div key={comp} className="custom-control custom-radio mb-3">
              <input className="custom-control-input" id={comp} checked={compliance === comp} type="radio" onChange={(e) => e.target.checked && setCompliance(comp)}/>
              <label className="custom-control-label" htmlFor={comp}>{comp}</label>
            </div>) }
            <div className="custom-control custom-radio mb-3">
              <input className="custom-control-input" id="deep" checked={compliance === false} type="radio" onChange={(e) => e.target.checked && setCompliance(false)}/>
              <label className="custom-control-label" htmlFor="deep">Deep Scan</label>
            </div>
          </FormGroup>
          { (account && account.platform === 'AWS') && <FormGroup>
            <Label for="awsChinaSelect"><strong>Does this AWS account belong to China region?</strong></Label>
              <span className="clearfix" />
              <label className="custom-toggle">
                <input type="checkbox" value={awsChina} onChange={e => setAwsChina(e.target.checked)} />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </FormGroup> }
        </Form>
        <Button onClick={() => startScan()} className="mt-2" color="primary" type="button" block disabled={ process || !account || compliance === null}>Start Scan</Button>

        { message && (message.type === 'success'
          ? <Alert className="mt-4 text-center" color="success">
              <span className="alert-inner--icon">
                <i className="fas fa-check" />
              </span>{" "}
              <span className="alert-inner--text">&nbsp;&nbsp;{message.text}</span>
            </Alert>
          : <Alert className="mt-4 text-center" color="danger">
          <span className="alert-inner--icon">
            <i className="fas fa-exclamation-triangle" />
          </span>{" "}
          <span className="alert-inner--text">&nbsp;&nbsp;{message.text}</span>
        </Alert> )}
      </Col>
    </Row>
  )
}

const Dashboard = () => {

  const { profile, accounts, subscription, scans } = useContext(UserContext)

  const [scansData, setScansData] = useState(scanStats.data)

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  useEffect(() => {
    if(!scans) return

    let counts = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [{
          label: "Scans",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          maxBarThickness: 10,
      }, ],
    }

    const data = scans.reduce((acc, scan) => {
      const month = new Date(scan.created).getMonth()
      const year = new Date(scan.created).getYear()
      if(year === new Date().getYear()) acc.datasets[0].data[month] += 1
      return acc
    }, counts)

    setScansData(data)

  }, [scans])

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>

          {/* New Scan */}
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow">
              <CardHeader className="bg-transparent text-center">
                <h2 className="mb-0">New Scan</h2>
              </CardHeader>
              <hr className="my-0"/>
              <CardBody className="my-5 mx-3">
                <ScanWizard profile={profile} accounts={accounts} subscription={subscription} scans={scans} />
              </CardBody>
            </Card>
          </Col>

          <Col xl="5">
            <Card className="shadow bg-gradient-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0 text-light">Scans Performed <small style={{ float: 'right' }}>{new Date().getFullYear()}</small></h2>
                  </div>
                </Row>
              </CardHeader>
              <hr className="my-0 bg-light"/>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={scansData}
                    options={scanStats.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
