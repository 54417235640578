import Login from 'views/auth/Login'
import Register from 'views/auth/Register'
import ForgotPassword from 'views/auth/ForgotPassword'

import Dashboard from 'views/admin/Dashboard'
import Scans from 'views/admin/Scans'
import Accounts from 'views/admin/Accounts'
import Profile from 'views/admin/Profile'
import Subscription from 'views/admin/Subscription'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/scans",
    name: "Scans",
    icon: "fas fa-tasks text-orange",
    component: Scans,
    layout: "/admin",
  },
  {
    path: "/accounts",
    name: "Accounts",
    icon: "fas fa-cloud text-info",
    component: Accounts,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: "ni ni-credit-card text-green",
    component: Subscription,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-red",
    component: ForgotPassword,
    layout: "/auth",
  },
];
export default routes;
