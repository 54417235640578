import { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserContext } from 'lib/context';
import { useUserData } from 'lib/hooks';
import { initGA, logPageView } from './analytics'

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

function App() {

    useEffect(() => {
        initGA();
        logPageView();
    }, []);

    const userData = useUserData();

    return (
        <UserContext.Provider value={userData}>
            { userData.user ? <Switch>
                <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                <Redirect from="/" to="/admin/dashboard" />
            </Switch> : <Switch>
                <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                <Redirect from="/" to="/auth/login" />
            </Switch> }
        </UserContext.Provider>
    );
}

export default App;