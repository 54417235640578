import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import App from './App'

import 'assets/plugins/nucleo/css/nucleo.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/scss/argon-dashboard-react.scss'

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Router>
      <App />
    </Router>
  </Elements>,
  document.getElementById("root")
);
