import { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { doc, collection, onSnapshot, query, orderBy } from 'firebase/firestore'
import { auth, db } from './firebase/init'
import { getUser, getSubscription, getAccounts, getScans } from './firebase/firestore'

export function useUserData() {
  const [user, loading, error] = useAuthState(auth)
  const [profile, setProfile] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [scans, setScans] = useState([])

  let profileUnsubscribe = null
  let accountsUnsubscribe = null
  let subscriptionUnsubscribe = null
  let scansUnsubscribe = null

  const setListerners = async () => {
    profileUnsubscribe = onSnapshot(doc(db, 'users', user.uid), doc => setProfile(doc.data()))
    accountsUnsubscribe = onSnapshot(query(collection(db, 'users', user.uid, 'accounts'), orderBy('alias')), snapshot => {
      const newAccounts = snapshot.docs.map(doc => {
        const {id, alias, platform, added} = doc.data()
        return {id, alias, platform, added}
      })
      setAccounts(newAccounts)
    })
    subscriptionUnsubscribe = onSnapshot(doc(db, 'subscriptions', user.uid), doc => setSubscription(doc.data()))
    scansUnsubscribe = onSnapshot(query(collection(db, 'users', user.uid, 'scans'), orderBy('created', 'desc')), snapshot => {
      const newScans = snapshot.docs.map(doc => doc.data())
      setScans(newScans)
    })
  }

  useEffect(() => {
    (async () => {
      if(user) {
        const profile = await getUser(user.uid)
        const accounts = await getAccounts(user.uid)
        const subscription = await getSubscription(user.uid)
        const scans = await getScans(user.uid)
        setProfile(profile)
        setSubscription(subscription)
        setAccounts(accounts)
        setScans(scans)
        setListerners()
      } else {
        setProfile(null)
        setSubscription(null)
        setAccounts([])
        setScans([])
      }
    })()

    return () => {
      if(user) {
        if (profileUnsubscribe) profileUnsubscribe()
        if (accountsUnsubscribe) accountsUnsubscribe()
        if (subscriptionUnsubscribe) subscriptionUnsubscribe()
        if (scansUnsubscribe) scansUnsubscribe()
      }
    }
  }, [user])

  return { user, profile, subscription, accounts, scans };
}