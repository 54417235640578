import { useState } from 'react';
import { UncontrolledAlert } from 'reactstrap'

const awsRole = JSON.stringify({
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Action": [
		"devops-guru:ListNotificationChannels",
                "dlm:GetLifecyclePolicies",
		"kendra:ListIndices",
		"proton:ListEnvironmentTemplates",
		"glue:GetSecurityConfigurations",
		"kinesisvideo:ListStreams",
		"ses:ListIdentities",
		"ses:DescribeActiveReceiptRuleSet",
		"ssm:GetServiceSetting",
		"airflow:ListEnvironments",
		"profile:ListDomains",
		"timestream:DescribeEndpoints",
		"memorydb:DescribeClusters",
		"kafka:ListClusters",
		"apprunner:ListServices",
		"finspace:ListEnvironments",
		"shield:DescribeEmergencyContactSettings",
		"healthlake:ListFHIRDatastores",
		"codeartifact:ListDomains",
		"auditmanager:GetSettings",
		"appflow:ListFlows",
		"elastictranscoder:ListPipelines",
		"databrew:ListJobs",
		"managedblockchain:ListNetworks",
		"connect:ListInstances",
		"backup:ListBackupVaults",
		"lookoutequipment:ListDatasets",
		"iotsitewise:DescribeDefaultEncryptionConfiguration",
		"geo:List*",
		"lookoutvision:ListProjects",
		"lookoutmetrics:ListAnomalyDetectors",
		"lex:ListBots",
		"forecast:ListDatasets",
		"qldb:ListLedgers",
		"timestream:ListDatabases",
            ],
            "Resource": "*"
        }
    ]
}, null, 2);

const AccountDocs = ({ platform }) => {

    const [showAlert, setShowAlert] = useState(false);

    return (
        <div className="modal-body" style={styles.container}>
            { platform === 'AWS'
                ? (<>
                    <p>Create a &quot;CloudInspekt&quot; user, with the <code>SecurityAudit</code> policy.</p>
                    <ol>
                    <li>Log into your AWS account as an admin or with permission to create IAM resources.</li>
                    <li>Navigate to the <a href="https://console.aws.amazon.com/iam/home" target="_blank" rel="noopener noreferrer">IAM console</a>.</li>
                    <li>Click on <a href="https://console.aws.amazon.com/iam/home?region=us-east-1#/users" target="_blank" rel="noopener noreferrer">Users</a> </li>
                    <li><a href="https://console.aws.amazon.com/iam/home?region=us-east-1#/users$new?step=details" target="_blank" rel="noopener noreferrer">Create a new user (Add user)</a></li>
                    <li>Set the username to <code>CloudInspekt</code></li>
                    <li>Set the access type to &quot;Programmatic access&quot;, click Next.</li>
                    <li>Select &quot;Attach existing policies directly&quot; and select the SecurityAudit policy.</li>
                    <li>Click &quot;Create policy&quot; to create a supplemental policy (some permissions are not included in SecurityAudit).</li>
                    <li>Click the &quot;JSON&quot; tab and paste the following permission set.
                    <code onClick={() => {
                        navigator.clipboard.writeText(awsRole);
                        setShowAlert(true);
                        setTimeout(() => setShowAlert(false), 5000);
                    }}><pre style={styles.code}>{awsRole}</pre></code>
                    </li>
                    <li>Click &quot;Review policy.&quot;</li>
                    <li>Provide a name (<code>CloudInspektSupplemental</code>) and click &quot;Create policy.&quot;</li>
                    <li>Return to the &quot;Create user&quot; page and attach the newly-created policy. Click &quot;Next: tags.&quot;</li>
                    <li>Add the existing AWS Security Audit policy</li>
                    <li>Set tags as needed and then click on &quot;Create user&quot;.</li>
                    <li>Make sure you safely store the Access key ID and Secret access key.</li>
                    <li>Paste them into the corresponding AWS credentials section.</li>
                    </ol>
                </>) : platform === 'Azure'
                ? (<ol>
                    <li>Log into your Azure Portal and navigate to the Azure Active Directory service.</li>
                    <li>Select App registrations and then click on New registration.</li>
                    <li>Enter &quot;CloudInspekt&quot; and/or a descriptive name in the Name field, take note of it, it will be used again in step 3.</li>
                    <li>Leave the &quot;Supported account types&quot; default: &quot;Accounts in this organizational directory only (YOURDIRECTORYNAME)&quot;.</li>
                    <li>Click on Register.</li>
                    <li>Copy the Application ID and Paste it below.</li>
                    <li>Copy the Directory ID and Paste it below.</li>
                    <li>Click on Certificates &amp; secrets.</li>
                    <li>Under Client secrets, click on New client secret.</li>
                    <li>Enter a Description (i.e. CloudInspekt-2022) and select Expires &quot;In 1 year&quot;.</li>
                    <li>Click on Add.</li>
                    <li>The Client secret value appears only once, make sure you store it safely.</li>
                    <li>Navigate to Subscriptions.</li>
                    <li>Click on the relevant Subscription ID, copy and paste the ID below.</li>
                    <li>Click on &quot;Access Control (IAM)&quot;.</li>
                    <li>Go to the Role assignments tab.</li>
                    <li>Click on &quot;Add&quot;, then &quot;Add role assignment&quot;.</li>
                    <li>In the &quot;Role&quot; drop-down, select &quot;Security Reader&quot;.</li>
                    <li>Leave the &quot;Assign access to&quot; default value.</li>
                    <li>In the &quot;Select&quot; drop-down, type the name of the app registration (e.g. &quot;CloudInspekt&quot;) you created and select it.</li>
                    <li>Click &quot;Save&quot;.</li>
                    <li>Repeat the process for the role &quot;Log Analytics Reader&quot;</li>
                    </ol>)
                : platform === 'GCP' && (<>
                <ol>
                    <li>Log into your Google Cloud console and &quot;Activate&quot; your Cloud Shell.</li>
                    <li>Create a new file called cloudinspekt-security-audit-role.yaml. You can use: <code>nano cloudinspekt-security-audit-role.yaml</code>.</li>
                    <li>Copy and paste the following yaml code in the file on your Cloud Shell, press Ctrl + X and type &quot;Y&quot; to save the file.
                    <code>
                    <pre onClick={e => {
                        navigator.clipboard.writeText(e.target.innerText);
                        setShowAlert(true);
                        setTimeout(() => setShowAlert(false), 5000);
                    }} style={styles.code}>
{`name: roles/CloudInspektCSPMSecurityAudit
title: CloudInspekt CSPM Security Audit
    - includedPermissions:
    - cloudkms.cryptoKeys.list
    - cloudkms.keyRings.list
    - cloudsql.instances.list
    - cloudsql.users.list
    - compute.autoscalers.list
    - compute.backendServices.list
    - compute.disks.list
    - compute.firewalls.list
    - compute.healthChecks.list
    - compute.instanceGroups.list
    - compute.instances.getIamPolicy
    - compute.instances.list
    - compute.networks.list
    - compute.projects.get
    - compute.securityPolicies.list
    - compute.subnetworks.list
    - compute.targetHttpProxies.list
    - container.clusters.list
    - dns.managedZones.list
    - iam.serviceAccountKeys.list
    - iam.serviceAccounts.list
    - logging.logMetrics.list
    - logging.sinks.list
    - monitoring.alertPolicies.list
    - resourcemanager.folders.get
    - resourcemanager.folders.getIamPolicy
    - resourcemanager.folders.list
    - resourcemanager.hierarchyNodes.listTagBindings
    - resourcemanager.organizations.get
    - resourcemanager.organizations.getIamPolicy
    - resourcemanager.projects.get
    - resourcemanager.projects.getIamPolicy
    - resourcemanager.projects.list
    - resourcemanager.resourceTagBindings.list
    - resourcemanager.tagKeys.get
    - resourcemanager.tagKeys.getIamPolicy
    - resourcemanager.tagKeys.list
    - resourcemanager.tagValues.get
    - resourcemanager.tagValues.getIamPolicy
    - resourcemanager.tagValues.list
    - storage.buckets.getIamPolicy
    - storage.buckets.list
stage: GA`}
                    </pre>
                    </code></li>
                    <li>Run the following command to create the role, use your Organization Id to create the Role at the Org Level: <code>gcloud iam roles create CloudInspektCSPMSecurityAudit --organization=YOUR_ORGANIZATION_ID --file=cloudinspekt-security-audit-role.yaml</code></li>
                    </ol>
                    <strong>Create Service Account</strong>
                    <ol>
                    <li>Log into your Google Cloud console and navigate to IAM Admin &gt; Service Accounts.</li>
                    <li>Click on &quot;Create Service Account&quot;.</li>
                    <li>Enter &quot;CloudInspekt&quot; in the &quot;Service account name&quot;, then enter &quot;CloudInspekt API Access&quot; in the description.</li>
                    <li>Click on Continue.</li>
                    <li>Select the role: Custom &gt; CloudInspekt CSPM Security Audit.</li>
                    <li>Click on Continue.</li>
                    <li>Click on &quot;Create Key&quot;.</li>
                    <li>Leave the default JSON selected.</li>
                    <li>Click on &quot;Create&quot;.</li>
                    <li>The key will be downloaded to your machine.</li>
                    <li>Open the JSON key file, in a text editor and copy the Project Id, Client Email and Private Key values into the GCP credentials section.</li>
                    </ol>
                </>)
            }

            <UncontrolledAlert style={styles.alert} color="success" fade={true} isOpen={showAlert}>
                <span className="alert-inner--icon">
                    <i className="ni ni-like-2 mr-3" />
                </span>
                <span className="alert-inner--text">
                    Successfully copied to clipboard
                </span>
            </UncontrolledAlert>
        </div>
    );
}

const styles = {
    container: {
        maxHeight: '75vh', 
        overflow: 'scroll'
    },
    code: {
        backgroundColor: '#ededed',
        cursor: 'pointer',
        padding: '2rem',
        borderRadius: '8px',
        margin: '1rem 0'
    },
    alert: {
        position: 'fixed',
        bottom: 20,
        right: 40
    }
}

export default AccountDocs;