import {
    Row,
    Col,
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
} from 'reactstrap'

const PlanCard = ({ plan, getPlan, subscribed = false }) => {

    const { id, priceId, name, price, recommended, accounts, scans, compliances, trial } = plan

    return (
        <Col xl={subscribed ? '5' : '4'} className="mb-3">
            <Card className={ trial ? 'bg-gradient-warning' : recommended ? 'bg-gradient-info' : 'bg-gradient-primary mt-3'}>
                <CardHeader className="bg-transparent border-0 text-center">
                    <h3 className="text-white mb-0 text-center">{name}</h3>
                    {(recommended && !subscribed) && <Badge className="text-uppercase bg-success text-white ls-1 mb-1" pill><i className="fas fa-star"/> Recommended</Badge>}
                </CardHeader>
                <CardBody>
                    <Row className="justify-content-center">
                    <img className="rounded-circle" width="120" alt="..." src={require(`../../assets/img/plans/${plan.name}.gif`).default} />
                    </Row>
                    <h2 className="text-white mb-0 text-center mt-4"><sup>$</sup> {price} /<sub> month</sub></h2>
                    <hr className="bg-light"/>
                    <Row>
                    <Col className="justify-content-center text-center text-white">
                        <p><i className="fas fa-cloud"/> &nbsp; <strong>Upto {accounts} cloud account(s)</strong></p>
                        <p><i className="fas fa-search"/> &nbsp; <strong>Upto {scans} scans / month</strong></p>
                        <p><i className="fas fa-tasks"/> &nbsp; <strong>Run {compliances.join(', ')} audit</strong></p>
                        { !subscribed && <Button className="mt-5" size="lg" color="default" block onClick={(e) => getPlan(id, priceId)}>Select Plan</Button> }
                    </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    );
}

export default PlanCard;