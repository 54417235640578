import { useState, useContext } from 'react'

import {
    Alert,
    Badge,
    Col,
    Label,
    Button,
    Card,
    CardBody,
    Modal,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    FormGroup,
    Form,
    Input,
} from 'reactstrap'

import AccountDocs from './AccountDocs'

import { addAccount } from 'lib/firebase/firestore'
import { UserContext } from 'lib/context'

const NewCloudAccount = () => {

    const { user } = useContext(UserContext)
    const { uid } = user

    const [activeTab, setActiveTab] = useState(0)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const [accountAlias, setAccountAlias] = useState('')
    
    const [accessKeyId, setAccessKeyId] = useState('')
    const [secretAccessKey, setSecretAccessKey] = useState('')

    const [applicationId, setApplicationId] = useState('')
    const [keyValue, setKeyValue] = useState('')
    const [directoryId, setDirectoryId] = useState('')
    const [subscriptionId, setSubscriptionId] = useState('')

    const [accountType, setAccountType] = useState('')
    const [projectId, setProjectId] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [privateKey, setPrivateKey] = useState('')

    const [showModal, setShowModal] = useState(false)

    const clearFields = () => {
        setAccountAlias('')
        setAccessKeyId('')
        setSecretAccessKey('')
        setApplicationId('')
        setKeyValue('')
        setDirectoryId('')
        setSubscriptionId('')
        setAccountType('')
        setProjectId('')
        setAccountEmail('')
        setPrivateKey('')
    }
    
    const toggleNavs = (e, index) => {
        e.preventDefault()
        setActiveTab(index)
        clearFields()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        let platform = null
        let keys = {}
        switch(activeTab) {
            case 1:
                platform = 'AWS';
                keys = { accessKeyId, secretAccessKey }
                break;
            case 2:
                platform = 'Azure';
                keys = { ApplicationID: applicationId, KeyValue: keyValue, DirectoryID: directoryId, SubscriptionID: subscriptionId }
                break;
            case 3:
                platform = 'GCP';
                keys = { type: accountType, project: projectId, client_email: accountEmail, private_key: privateKey }
                break;
            default:
                break;
        }

        console.log(keys)

        const error = await addAccount(uid, accountAlias, platform, keys)
        if(error) {
            setError(error.code)
            setTimeout(() => setError(null), 10000)
        } else {
            setSuccess(true)
            setError(null)
            clearFields()
            setTimeout(() => {
                setSuccess(false)
                setActiveTab(0)
            }, 5000)
        }
        setLoading(false)
    }

    return (
      <>
        <div className="nav-wrapper">
          <Nav className="nav-fill flex-column flex-md-row" id="activeTab-icons-text" pills role="tablist">
            <NavItem>
              <NavLink aria-selected={activeTab === 1} className={`mb-sm-3 mb-md-3 ${ activeTab === 1 ? 'bg-gradient-warning' : ''}`}
                onClick={e => toggleNavs(e, 1)} href="#!" role="tab">
                    <img alt="AWS" src={require('../../assets/img/platforms/aws.png').default} height="60" className="mb-2 mt-3" />
                    <h3 className={ activeTab === 1 && 'text-secondary' }>Amazon Web Services</h3>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={activeTab === 2} className={`mb-sm-3 mb-md-3 ${ activeTab === 2 ? 'bg-gradient-info' : ''}`}
                onClick={e => toggleNavs(e, 2)} href="#!" role="tab">
                    <img alt="Azure" src={require('../../assets/img/platforms/azure.png').default} height="60" className="mb-2 mt-3" />
                    <h3 className={ activeTab === 2 && 'text-secondary' }>Microsoft Azure</h3>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink aria-selected={activeTab === 3} className={`mb-sm-3 mb-md-3 ${ activeTab === 3 ? 'bg-gradient-primary' : ''}`}
                onClick={e => toggleNavs(e, 3)} href="#!" role="tab">
                    <img alt="Azure" src={require('../../assets/img/platforms/gcp.png').default} height="60" className="mb-2 mt-3" />
                    <h3 className={ activeTab === 3 && 'text-secondary' }>Google Cloud Platform</h3>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <Card className="pt-3">
          <CardBody>
            { activeTab <= 0 ? <h4 className="text-center">Please select a platform</h4> :
                <TabContent activeTab={`tab-${activeTab}`}>
                    <Form role="form">
                        <Badge color="primary" href="#info" onClick={() => setShowModal(activeTab === 1 ? 'AWS' : activeTab === 2 ? 'Azure' : 'GCP')}>Where do I get these details?</Badge>
                        
                        <FormGroup className="my-3" row>
                            <Label for="accountAlias" sm={3}>Account Alias</Label>
                            <Col sm={9}><Input id="accountAlias" className="p-3" placeholder="A unique name for you to identify this account among others" type="text" autoComplete="off" value={accountAlias} onChange={e => setAccountAlias(e.target.value)} /></Col>
                        </FormGroup>
                    </Form>

                    {/* AWS */}
                    <TabPane tabId="tab-1">
                        <Form role="form">
                            <FormGroup className="mb-3" row>
                                <Label for="accessKeyId" sm={3}>Access Key ID</Label>
                                <Col sm={9}><Input id="accessKeyId" className="p-3" placeholder="Access Key ID" type="password" value={accessKeyId} onChange={e => setAccessKeyId(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="secretAccessKey" sm={3}>Secret Access Key</Label>
                                <Col sm={9}><Input id="secretAccessKey" className="p-3" placeholder="Secret Access Key" type="password" value={secretAccessKey} onChange={e => setSecretAccessKey(e.target.value)} /></Col>
                            </FormGroup>
                        </Form>
                    </TabPane>

                    {/* Azure */}
                    <TabPane tabId="tab-2">
                        <Form role="form">
                            <FormGroup className="mb-3" row>
                                <Label for="applicationId" sm={3}>Application ID</Label>
                                <Col sm={9}><Input id="applicationId" className="p-3" placeholder="Application ID" type="password" value={applicationId} onChange={e => setApplicationId(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="keyValue" sm={3}>Key Value</Label>
                                <Col sm={9}><Input id="keyValue" className="p-3" placeholder="Key Value" type="password" value={keyValue} onChange={e => setKeyValue(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="directoryId" sm={3}>Directory ID</Label>
                                <Col sm={9}><Input id="directoryId" className="p-3" placeholder="Directory ID" type="password" value={directoryId} onChange={e => setDirectoryId(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="subscriptionId" sm={3}>Subscription ID</Label>
                                <Col sm={9}><Input id="subscriptionId" className="p-3" placeholder="Subscription ID" type="password" value={subscriptionId} onChange={e => setSubscriptionId(e.target.value)} /></Col>
                            </FormGroup>
                        </Form>
                    </TabPane>

                    {/* GCP */}
                    <TabPane tabId="tab-3">
                        <Form role="form">
                            <FormGroup className="mb-3" row>
                                <Label for="accountType" sm={3}>Account Type</Label>
                                <Col sm={9}><Input id="accountType" className="p-3" placeholder="Account Type" type="password" value={accountType} onChange={e => setAccountType(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="projectId" sm={3}>Project ID</Label>
                                <Col sm={9}><Input id="projectId" className="p-3" placeholder="Project ID" type="password" value={projectId} onChange={e => setProjectId(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="accountEmail" sm={3}>Account Email</Label>
                                <Col sm={9}><Input id="accountEmail" className="p-3" placeholder="Account Email" type="password" value={accountEmail} onChange={e => setAccountEmail(e.target.value)} /></Col>
                            </FormGroup>
                            <FormGroup className="mb-3" row>
                                <Label for="privateKey" sm={3}>Private Key</Label>
                                <Col sm={9}><Input id="privateKey" className="p-3" placeholder="Private Key" type="password" value={privateKey} onChange={e => setPrivateKey(e.target.value)} /></Col>
                            </FormGroup>
                        </Form>
                    </TabPane>

                    { error && <Alert className="text-center" color="danger">
                        <span className="alert-inner--icon">
                        <i className="fas fa-exclamation-triangle" />
                        </span>{" "}
                        <span className="alert-inner--text">&nbsp;&nbsp;{error}</span>
                    </Alert> }

                    { success && <Alert className="text-center" color="success">
                        <span className="alert-inner--icon">
                            <i className="ni ni-like-2" />
                        </span>{" "}
                        <span className="alert-inner--text">&nbsp;&nbsp;
                            <strong>Congratulations!</strong> Account added successfully.
                        </span>
                    </Alert> }
                
                    <div className="text-center">
                        <Button className="my-4" color="primary" type="button" onClick={handleSubmit} disabled={loading || !accountAlias || (activeTab === 1 && (!accessKeyId || !secretAccessKey))  || (activeTab === 2 && (!applicationId || !keyValue || !directoryId || !subscriptionId)) || (activeTab === 3 && (!accountType || !projectId || !accountEmail || !privateKey)) }>Save</Button>
                    </div>
                </TabContent>
            }
            <Modal style={{ minWidth: '50vw' }} className="modal-dialog-centered" isOpen={showModal} toggle={() => setShowModal(false)}>
                <div className="modal-header">
                    <h3 className="modal-title mb-0">CloudInspekt credentials for {showModal}</h3>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setShowModal(false)}>
                    <i className="fas fa-window-close" style={{ color: 'red' }}/>
                    </button>
                </div>
                { showModal && <AccountDocs platform={showModal} /> }
            </Modal>
          </CardBody>
        </Card>
      </>
    )
}

export default NewCloudAccount;