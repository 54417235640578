import { useState, useRef } from 'react'
import { Link } from "react-router-dom"

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap'

import { mailFormat } from 'constants/shared'
import { emailSignIn, googleSignIn, githubSignIn } from 'lib/firebase/auth'

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [emailValid, setEmailValid] = useState(false);

  const emailErrorContainer = useRef(null);
  const emailError = useRef(null);

  const handleEmail = (e) => {
    emailErrorContainer.current.style.display = 'block';
    setEmail(e.target.value);
    const valid = mailFormat.test(e.target.value);
    if(valid) {
      emailError.current.innerHTML = 'valid';
      emailError.current.classList.replace('text-danger', 'text-success');
      setEmailValid(true);
    } else {
      emailError.current.innerHTML = 'invalid';
      emailError.current.classList.replace('text-success', 'text-danger');
      setEmailValid(false);
    }
  }

  const handleLogin = async (e) => {
    const error = await emailSignIn(email, password)
    if(error) {
      switch(error.code) {
        case 'auth/user-not-found':
          setError('No user found with this email address.')
          break
        case 'auth/invalid-credential':
        case 'auth/wrong-password':
          setError('Password is incorrect')
          break
        case 'auth/timeout':
        case 'auth/too-many-requests':
          setError('Too many requests, please try again after some time')
          break
        case 'auth/user-disabled':
          setError('This account has been disabled')
          break
        default:
          setError('Unknown error, sorry for the moment')
          break
      }
    } else setError(null)
    setTimeout(() => setError(null), 5000)
  }

  return (
    <>
      <Col lg="6" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-4">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button className="btn-neutral btn-icon" color="default" href="#pablo" onClick={(e) => googleSignIn()}>
                <span className="btn-inner--icon">
                  <img alt="Google Login" src={require("../../assets/img/icons/common/google.svg").default} />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
              <Button className="btn-neutral btn-icon" color="default" href="#pablo" onClick={(e) => githubSignIn()}>
                <span className="btn-inner--icon">
                  <img alt="Github Login" src={require("../../assets/img/icons/common/github.svg").default} />
                </span>
                <span className="btn-inner--text">Github</span>
              </Button>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" autoComplete="new-email" onChange={handleEmail} value={email} />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic my-4" ref={emailErrorContainer} style={{ display: "none" }}>
                <small>
                  Email:{" "}
                  <span ref={emailError} className="text-danger font-weight-700">invalid</span>
                </small>
              </div>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Password" type="password" autoComplete="new-password" onChange={(e) => setPassword(e.target.value)} value={password} />
                </InputGroup>
              </FormGroup>

              { error && <Alert className="mt-4 text-center" color="danger">
                <span className="alert-inner--icon">
                  <i className="fas fa-exclamation-triangle" />
                </span>{" "}
                <span className="alert-inner--text">&nbsp;&nbsp;{error}</span>
                </Alert> }

              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={handleLogin} disabled={!(emailValid && password.length > 0)}>
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/forgot-password">
              <small>Forgot password?</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link className="text-light" to="/auth/register">
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
