import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

const PROJECT_ID = process.env.REACT_APP_PROJECT

const firebaseConfig = {
    apiKey: "AIzaSyDNMq1vrTTP3SXgGrbuc_t2spj-HpAcGF4",
    authDomain: `${PROJECT_ID}.firebaseapp.com`,
    projectId: PROJECT_ID,
    storageBucket: `${PROJECT_ID}.appspot.com`,
    messagingSenderId: "1061076937613",
    appId: "1:1061076937613:web:a3369e1dd8022ed3cbebc4",
    measurementId: "G-X1WG8CW89X"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

if (process.env.REACT_APP_ENV === "dev") {
    connectAuthEmulator(auth, 'http://localhost:9099/')
    connectFirestoreEmulator(db, 'localhost', 8080)
    connectStorageEmulator(storage, 'localhost', 9199)
}

export {
    app,
    auth,
    db,
    storage
}