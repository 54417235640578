import { useContext } from 'react'
import { UserContext } from 'lib/context'
import { Container, Row, Col } from 'reactstrap'

const UserHeader = () => {

  const { user } = useContext(UserContext)
  const { displayName } = user

  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "600px",
          backgroundImage: `url(${require("../../assets/img/theme/profile-bg.jpg").default})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">Hello {displayName.split(' ')[0]}</h1>
              <p className="text-white mt-0 mb-5">
                This is your profile page.
                You can see your personal details here.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
