import { useContext} from 'react'

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  DropdownItem,
  Media,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap'

import Header from 'components/Headers/Header'
import NewCloudAccount from 'components/Custom/NewCloudAccount'

import { UserContext } from 'lib/context'

import awsLogo from 'assets/img/platforms/aws.png'
import azureLogo from 'assets/img/platforms/azure.png'
import gcpLogo from 'assets/img/platforms/gcp.png'

const Accounts = () => {

  const { accounts, subscription } = useContext(UserContext)

  return (
    <>
      <Header />

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 text-center">Cloud Accounts</h3>
              </CardHeader>
              { subscription && subscription.expirationDate < Date.now()
                ? <CardBody className="bg-gradient-danger"><h4 className="text-center text-white my-7">Plan expired</h4></CardBody>
                : accounts && accounts.length > 0
                ? <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Platform</th>
                        <th scope="col">Alias</th>
                        <th scope="col">Date Added</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      { accounts.map(account => (
                        <tr key={account.id}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <img width="60" className="py-2 mr-5" alt={account.alias} src={account.platform === 'AWS' ? awsLogo : account.platform === 'Azure' ? azureLogo : account.platform === 'GCP' && gcpLogo} />
                              <Media>
                                <span className="mb-0 text-sm">{account.platform === 'AWS' ? 'Amazon Web Services' : account.platform === 'Azure' ? 'Microsoft Azure' : account.platform === 'GCP' && 'Google Cloud Platform' }</span>
                              </Media>
                            </Media>
                          </th>
                          <td>{account.alias}</td>
                          <td>{new Date(account.added).toUTCString()}</td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle className="btn-icon-only text-light" href="#!" role="button" size="sm" color="" onClick={(e) => e.preventDefault()}>
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem href="#!" onClick={(e) => e.preventDefault()}><i className="fas fa-pen" /> Edit Alias</DropdownItem>
                                <DropdownItem href="#!" onClick={(e) => e.preventDefault()}><i className="fas fa-history" /> Scan History</DropdownItem>
                                {/* <DropdownItem href="#!" onClick={(e) => e.preventDefault()}><i className="fas fa-trash" /> Delete</DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
              : <CardBody className="bg-gradient-light"><h4 className="text-center text-white my-7">No accounts found</h4></CardBody> }
            </Card>
          </div>
        </Row>

        { (accounts && subscription && accounts.length < subscription.accounts && subscription.expirationDate > Date.now())
          && <Row className="mt-4">
          <div className="col">
            <Card className="shadow">
              
              <CardHeader className="bg-transparent">
                <h3 className="mb-0 text-center">Add a new account</h3>
              </CardHeader>

              <CardBody>
                <NewCloudAccount className="bg-gray-800" />
              </CardBody>
            </Card>
          </div>
        </Row> }
      </Container>
    </>
  );
};

export default Accounts;
