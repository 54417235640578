import { useState, useRef } from 'react'
import { Link } from "react-router-dom"

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { mailFormat } from 'constants/shared'
import { resetPassword } from 'lib/firebase/auth'

const ForgotPassword = () => {

  const [email, setEmail] = useState('');

  const emailErrorContainer = useRef(null);
  const emailError = useRef(null);

  const handleEmail = (e) => {
    emailErrorContainer.current.style.display = 'block';
    setEmail(e.target.value);
    const valid = mailFormat.test(e.target.value);
    if(valid) {
      emailError.current.innerHTML = 'valid';
      emailError.current.classList.replace('text-danger', 'text-success');
    } else {
      emailError.current.innerHTML = 'invalid';
      emailError.current.classList.replace('text-success', 'text-danger');
    }
  }

  const handleReset = (e) => {
      resetPassword(email);
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Fill in your email address</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Email" type="email" autoComplete="new-email" onChange={handleEmail} value={email} />
                </InputGroup>
              </FormGroup>
              <div className="text-muted font-italic my-4" ref={emailErrorContainer} style={{ display: "none" }}>
                <small>
                  Email:{" "}
                  <span ref={emailError} className="text-danger font-weight-700">invalid</span>
                </small>
              </div>

              <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={handleReset}>
                    Send Password Reset Link
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/login">
              <small>Login</small>
            </Link>
          </Col>
          <Col className="text-right" xs="6">
            <Link className="text-light" to="/auth/register">
              <small>Create new account</small>
            </Link>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ForgotPassword;
