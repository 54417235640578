import { useContext, useState, useEffect } from 'react'
import { UserContext } from 'lib/context'
import { updateAddress } from 'lib/firebase/firestore'

import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap'

import UserHeader from 'components/Headers/UserHeader'

const Profile = () => {

  const { user, profile } = useContext(UserContext)
  const { email, displayName, photoURL } = user

  const [editing, setEditing] = useState(false)

  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [zip, setZip] = useState('')

  const [loading, setLoading] = useState(false)

  const [message, setMessage] = useState(null)

  const initForm = () => {
    setLoading(false)
    setEditing(false)
    if(profile && profile.address) {
      setStreet(profile.address.street)
      setCity(profile.address.city)
      setCountry(profile.address.country)
      setZip(profile.address.zip)
    } else {
      setStreet('')
      setCity('')
      setCountry('')
      setZip('')
    }
  }

  const saveForm = async () => {
    setLoading(true)
    const address = { street, city, country, zip }
    const error = await updateAddress(user.uid, address)

    if(error) setMessage({ type: 'error', text: 'Unable to update address at the moment' })
    else setMessage({ type: 'success', text: 'Your address has been updated'})

    setLoading(false)
    setEditing(false)
    setTimeout(() => setMessage(null), 5000)
  }

  useEffect(() => initForm(), [profile])

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." className="rounded-circle" src={photoURL} />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
               
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      <div className="text-center">
                        <h3>{displayName}</h3>
                        { profile && <>
                          <div className="h4 font-weight-500">
                            { profile.address && <><i className="fas fa-map-marker-alt" />&nbsp;&nbsp;<span className="font-weight-light">{profile.address.city},</span> {profile.address.country}</> }
                          </div>
                          <div className="h5 font-weight-300 mt-4">
                            <p className="mb-1 h5 font-weight-400">User Since</p>
                            <i className="far fa-calendar" />&nbsp;&nbsp;
                            { new Date(profile.created).toDateString() }
                          </div>
                        </> }
                      </div>
                    </div>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    { editing
                      ? <Button color="primary" size="sm" onClick={() => saveForm()} disabled={loading || !street || !city || !country || !zip}>{ loading ? <i className="fas fa-circle-notch fa-spin px-2"/> : 'Save' }</Button>
                      : <Button color="primary" onClick={() => setEditing(true)} size="sm">Edit</Button> }
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="name">Name</label>
                          <Input className="form-control-alternative" id="name" placeholder="Full Name" type="text" value={displayName} disabled/>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="email">Email address</label>
                          <Input className="form-control-alternative" id="email" type="email" value={email} disabled/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Address
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="address">Street</label>
                          <Input className="form-control-alternative" id="address" placeholder="Street" type="text" value={street} onChange={(e) => setStreet(e.target.value)} disabled={!editing}/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="city">City</label>
                          <Input className="form-control-alternative" id="city" placeholder="City" type="text" value={city} onChange={(e) => setCity(e.target.value)} disabled={!editing}/>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="country">Country</label>
                          <Input className="form-control-alternative" id="country" placeholder="Country" type="text" value={country} onChange={(e) => setCountry(e.target.value)} disabled={!editing}/>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="zip">Postal / ZIP code</label>
                          <Input className="form-control-alternative" id="zip" placeholder="Postal / ZIP Code" type="number" value={zip} onChange={(e) => setZip(e.target.value)} disabled={!editing}/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>

                { message && (message.type === 'success'
                  ? <Alert className="mt-4 text-center" color="success">
                      <span className="alert-inner--icon">
                        <i className="fas fa-check" />
                      </span>{" "}
                      <span className="alert-inner--text">&nbsp;&nbsp;{message.text}</span>
                    </Alert>
                  : <Alert className="mt-4 text-center" color="danger">
                  <span className="alert-inner--icon">
                    <i className="fas fa-exclamation-triangle" />
                  </span>{" "}
                  <span className="alert-inner--text">&nbsp;&nbsp;{message.text}</span>
                </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
